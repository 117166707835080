import React from 'react'
import * as MantineCore from '@mantine/core'
import Link from 'next/link'
import { useDisclosure } from '@mantine/hooks'
import * as Icons from 'tabler-icons-react'
import { useSelector } from 'react-redux'

import { useIsMobile } from '../lib/useIsMobile'
import { VersionContext } from '../contexts/VersionContext'
import * as Selectors from '../redux/selectors'
import AccountModal from './AccountModal'

const BrandTitle = () => {
	const isMobile = useIsMobile()
	const version = React.useContext(VersionContext)?.version

	return (
		<Link href="/" legacyBehavior>
			<a className="brand-title">
				<MantineCore.Image
					src="/mountains-sun.svg"
					alt="guided Trekking icon"
					width={28}
					height={28}
					radius={'lg'}
				/>
				<span className="brand-header-text">
					{isMobile && false
						? version === 'TREKKING'
							? 'GT'
							: 'GP'
						: `Guided ${version === 'TREKKING' ? 'Trekking' : 'Peaks'}`}
				</span>
			</a>
		</Link>
	)
}

const countries = [
	{ label: 'Peru', href: '/climbing/south-america/peru' },
	{ label: 'Argentina', href: '/climbing/south-america/argentina' },
	{ label: 'Chile', href: '/climbing/south-america/chile' },
	{ label: 'Bolivia', href: '/climbing/south-america/bolivia' },
	{ label: 'Ecuador', href: '/climbing/south-america/ecuador' },
	{ label: 'Nepal', href: '/climbing/asia/nepal' },
]

const HeaderMegaMenu = () => {
	const [
		drawerOpened,
		{ toggle: toggleDrawer, close: closeDrawer },
	] = useDisclosure(false)
	const authedUser = useSelector(Selectors.authedUser)
	const isAdmin = useSelector(Selectors.isUserAdmin)
	const version = React.useContext(VersionContext).version
	const isPeaks = version === 'PEAKS'

	const links = countries.map((item) => (
		<MantineCore.UnstyledButton className={'subLink'} key={item.label}>
			<MantineCore.Group align="flex-start">
				<Link href={item.href} className="header-link">
					<MantineCore.Text size="sm" m="sm" fw={500} style={{ width: '100%' }}>
						{item.label}
					</MantineCore.Text>
				</Link>
			</MantineCore.Group>
		</MantineCore.UnstyledButton>
	))
	const mobileLinks = countries.map((item, key) => (
		<MantineCore.List.Item key={key}>
			<Link href={item.href} className="header-link">
				<MantineCore.Text
					size="sm"
					m="xs"
					ml={0}
					fw={500}
					style={{ width: '100%' }}
				>
					{item.label}
				</MantineCore.Text>
			</Link>
		</MantineCore.List.Item>
	))

	return (
		<div id="header2">
			<header>
				<MantineCore.Group
					style={{ justifyContent: 'space-between' }}
					h="100%"
					id="brand"
				>
					<div style={{ padding: '8px' }}>
						<BrandTitle />
					</div>

					{isPeaks && (
						<div className="mobile-hide">
							<MantineCore.Group>
								<Link href="/articles" legacyBehavior>
									<a className={'header-link'}>
										<MantineCore.Text size="sm" m="sm" fw={500}>
											Articles
										</MantineCore.Text>
									</a>
								</Link>

								<MantineCore.HoverCard
									position="bottom"
									radius="md"
									shadow="md"
									withinPortal
									// opened={true}
								>
									<MantineCore.HoverCard.Target>
										<a href="#" className={'header-link'}>
											<MantineCore.Center inline>
												<MantineCore.Box component="span" mr={5}>
													<MantineCore.Text size="sm" m="sm" fw={500}>
														Countries
													</MantineCore.Text>
												</MantineCore.Box>
												<Icons.ChevronDown
													style={{
														width: MantineCore.rem(16),
														height: MantineCore.rem(16),
													}}
												/>
											</MantineCore.Center>
										</a>
									</MantineCore.HoverCard.Target>

									<MantineCore.HoverCard.Dropdown
										style={{ overflow: 'hidden' }}
									>
										<MantineCore.SimpleGrid cols={1} spacing={0}>
											{links}
										</MantineCore.SimpleGrid>
									</MantineCore.HoverCard.Dropdown>
								</MantineCore.HoverCard>

								<Link href="/about" legacyBehavior style={{ margin: '16px' }}>
									<a className={'header-link'}>
										<MantineCore.Text size="sm" m="sm" fw={500}>
											About
										</MantineCore.Text>
									</a>
								</Link>
							</MantineCore.Group>
						</div>
					)}

					<div className="mobile-hide">
						<MantineCore.Group p="xs">
							{isAdmin && (
								<Link href="/admin" passHref>
									<MantineCore.Button
										title={`logged in as ${authedUser?.id} (${authedUser?.role})`}
									>
										Admin
									</MantineCore.Button>
								</Link>
							)}
							<AccountModal />
						</MantineCore.Group>
					</div>

					<MantineCore.Burger
						opened={drawerOpened}
						onClick={toggleDrawer}
						className="mobile-show"
						mr="sm"
					/>
				</MantineCore.Group>
			</header>

			<MantineCore.Drawer
				opened={drawerOpened}
				onClose={closeDrawer}
				size="100%"
				padding="md"
				title={<BrandTitle />}
				className="mobile-show"
				zIndex={10000}
			>
				<>
					<MantineCore.Divider />
					<MantineCore.ScrollArea
						h={`calc(100vh - ${MantineCore.rem(80)})`}
						mx="-md"
						p="md"
					>
						{isPeaks && (
							<>
								<Link
									href="/articles"
									legacyBehavior
									style={{ margin: '16px' }}
								>
									<a className={'header-link'}>
										<MantineCore.Text size="sm" fw={500}>
											Articles
										</MantineCore.Text>
									</a>
								</Link>
								<MantineCore.List style={{ margin: '16px', marginLeft: 0 }}>
									<MantineCore.Text size="sm" fw={500}>
										Countries:
									</MantineCore.Text>
									{mobileLinks}
								</MantineCore.List>
								<Link
									href="/about"
									className={'header-link'}
									legacyBehavior
									style={{ margin: '16px', marginLeft: 0 }}
								>
									<a className={'header-link'}>
										<MantineCore.Text size="sm" fw={500}>
											About
										</MantineCore.Text>
									</a>
								</Link>
								<MantineCore.Divider my="sm" />
							</>
						)}
						<div className="header-mobile-button-group">
							<MantineCore.Group grow pb="xl">
								{isAdmin && (
									<Link href="/admin" passHref>
										<MantineCore.Button
											fullWidth
											title={`logged in as ${authedUser?.id} (${authedUser?.role})`}
											className="header-mobile-button-group-button"
										>
											Admin
										</MantineCore.Button>
									</Link>
								)}
								<AccountModal />
							</MantineCore.Group>
						</div>
					</MantineCore.ScrollArea>
				</>
			</MantineCore.Drawer>
		</div>
	)
}

export default HeaderMegaMenu
