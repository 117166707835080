import * as React from 'react'
import * as Redux from 'react-redux'
import Link from 'next/link'
import * as MantineCore from '@mantine/core'
import * as MantineHooks from '@mantine/hooks'
import * as Icons from 'tabler-icons-react'
import * as NextRouter from 'next/router'

import * as Types from 'declarations'
import * as Selectors from '../redux/selectors'
import { VersionContext } from '../contexts/VersionContext'
import * as Actions from '../redux/actions'
import LoginForm from './LoginForm'
import RegisterForm from './RegisterForm'
import ResetPasswordForm from './ResetPasswordForm'
import { useIsMobile } from 'lib/useIsMobile'

const JoinCommunity = () => {
	const version = React.useContext(VersionContext)?.version

	return (
		<MantineCore.Text weight={500}>
			Join a community of{' '}
			{version === Types.VERSION.PEAKS ? 'mountaineers' : 'trekkers'}
		</MantineCore.Text>
	)
}

const AccountModal = () => {
	const [opened, { open, close }] = MantineHooks.useDisclosure(false)
	const version = React.useContext(VersionContext).version
	const router = NextRouter.useRouter()
	const isMobile = useIsMobile()

	const isGuest = Redux.useSelector(Selectors.isGuest)
	const isUser = Redux.useSelector(Selectors.isUser)
	const isGuide = Redux.useSelector(Selectors.isGuide)
	const isAdmin = Redux.useSelector(Selectors.isUserAdmin)

	const isSignUpOpen = Redux.useSelector(Selectors.isSignUpOpen)
	const isLogInOpen = Redux.useSelector(Selectors.isLoginOpen)

	React.useEffect(() => {
		if (isSignUpOpen) {
			open()
			setActiveTab('join')
		}
	}, [isSignUpOpen])

	React.useEffect(() => {
		if (isLogInOpen) {
			open()
			setActiveTab('login')
		}
	}, [isLogInOpen])

	const [activeTab, setActiveTab] = React.useState<string | null>('login')

	const dispatch = Redux.useDispatch()

	const logOut = () => {
		dispatch(Actions.logout())
		router.push('/')
	}

	const closeModal = () => {
		setActiveTab('login')
		close()
		dispatch(Actions.closeSignUp())
	}

	return (
		<>
			<MantineCore.Modal
				opened={opened}
				onClose={closeModal}
				title="Guided Peaks - Mountaineering community"
				size="xl"
				// show above mobile menu
				zIndex={15000}
			>
				{isGuest && (
					<MantineCore.Box>
						<MantineCore.Grid>
							{!isMobile && (
								<MantineCore.Grid.Col span={7}>
									<MantineCore.Box pr="xl">
										<MantineCore.Card
											shadow="sm"
											padding="lg"
											radius="md"
											withBorder
										>
											<MantineCore.Card.Section>
												<MantineCore.Image
													src={`/static/${
														version === Types.VERSION.PEAKS
															? 'peaks'
															: 'trekking'
													}_600.jpeg`}
													alt={`Guided ${
														version === Types.VERSION.PEAKS
															? 'peaks'
															: 'trekking'
													}`}
													height={200}
												/>
											</MantineCore.Card.Section>

											<MantineCore.Group position="apart" mt="md" mb="xs">
												<JoinCommunity />
											</MantineCore.Group>

											<MantineCore.Text size="sm" color="dimmed">
												<MantineCore.List spacing="xs" size="sm" center>
													<MantineCore.List.Item
														icon={
															<MantineCore.ThemeIcon size={24} radius="xl">
																<Icons.Trekking size="1rem" />
															</MantineCore.ThemeIcon>
														}
													>
														Find cheap local guides for peaks
													</MantineCore.List.Item>
													<MantineCore.List.Item
														icon={
															<MantineCore.ThemeIcon size={24} radius="xl">
																<Icons.PhotoUp size="1rem" />
															</MantineCore.ThemeIcon>
														}
													>
														Contribute pictures of routes
													</MantineCore.List.Item>
													{/* // todo: enable these once features are ready */}
													{/* <MantineCore.List.Item>
									Suggest new routes
								</MantineCore.List.Item>
								<MantineCore.List.Item>
									Wishlist trips you&apos;re interested in
								</MantineCore.List.Item> */}
													<MantineCore.List.Item
														icon={
															<MantineCore.ThemeIcon size={24} radius="xl">
																<Icons.Pencil size="1rem" />
															</MantineCore.ThemeIcon>
														}
													>
														Edit/correct/add information for routes
													</MantineCore.List.Item>
													{/* // todo: ask/answer questions
								// todo: get notified about new routes 
								// todo: receive discounted trips */}
												</MantineCore.List>
											</MantineCore.Text>

											{/* <MantineCore.Button
										variant="light"
										color="blue"
										fullWidth
										mt="md"
										radius="md"
										// onClick={openSignUp}
									>
										[Join]
									</MantineCore.Button> */}
										</MantineCore.Card>
									</MantineCore.Box>
								</MantineCore.Grid.Col>
							)}

							<MantineCore.Grid.Col span={isMobile ? 12 : 5}>
								<MantineCore.Box>
									{isMobile && (
										<>
											<JoinCommunity />
											<br />
										</>
									)}
									<MantineCore.Tabs
										variant="outline"
										value={activeTab}
										onTabChange={setActiveTab}
									>
										<MantineCore.Tabs.List>
											<MantineCore.Tabs.Tab value="login">
												Login
											</MantineCore.Tabs.Tab>
											<MantineCore.Tabs.Tab value="join">
												Join
											</MantineCore.Tabs.Tab>
										</MantineCore.Tabs.List>
										<MantineCore.Tabs.Panel value="login" pt="xs">
											<LoginForm onLogin={closeModal} />
											<MantineCore.Divider my="sm" />
											<MantineCore.Button
												variant="subtle"
												onClick={() => setActiveTab('forgot_password')}
												size="sm"
												pl={0}
											>
												Forgot password?
											</MantineCore.Button>
										</MantineCore.Tabs.Panel>

										<MantineCore.Tabs.Panel value="join" pt="xs">
											<RegisterForm onRegister={closeModal} />
										</MantineCore.Tabs.Panel>

										<MantineCore.Tabs.Panel value="forgot_password" pt="xs">
											<ResetPasswordForm />
										</MantineCore.Tabs.Panel>
									</MantineCore.Tabs>
								</MantineCore.Box>
							</MantineCore.Grid.Col>
						</MantineCore.Grid>
					</MantineCore.Box>
				)}
			</MantineCore.Modal>

			{isGuest && (
				<>
					<MantineCore.Button
						color={'orange'}
						onClick={open}
						variant="outline"
						fullWidth
					>
						login / join
					</MantineCore.Button>
				</>
			)}
			{isGuide && version === Types.VERSION.PEAKS && (
				<>
					<Link href="/guide">
						<MantineCore.Button variant="light" mr="sm" fullWidth>
							Guide section
						</MantineCore.Button>
					</Link>
				</>
			)}
			{(isUser || isGuide) && (
				<MantineCore.Menu shadow="md" width={200}>
					<MantineCore.Menu.Target>
						<MantineCore.Button variant="outline">
							Account&nbsp;&#x25BC;
						</MantineCore.Button>
					</MantineCore.Menu.Target>

					<MantineCore.Menu.Dropdown>
						{isGuide && version === Types.VERSION.PEAKS && (
							<>
								<Link href="/guide">
									<MantineCore.Menu.Item icon={<Icons.Mountain size={14} />}>
										Guide section
									</MantineCore.Menu.Item>
									<MantineCore.Menu.Divider />
								</Link>
							</>
						)}

						<MantineCore.Menu.Item
							onClick={() => logOut()}
							icon={<Icons.Logout size={14} />}
						>
							Log out
						</MantineCore.Menu.Item>
					</MantineCore.Menu.Dropdown>
				</MantineCore.Menu>
			)}
			{isAdmin && (
				<>
					<MantineCore.Button
						onClick={() => logOut()}
						// fullWidth
						variant="outline"
					>
						logout
					</MantineCore.Button>
				</>
			)}
		</>
	)
}

export default AccountModal
